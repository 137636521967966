<template>
  <div>
    <div class="box">
      <div class="container">
        <div class="sat">
          <h1 class="sat_left">
            <p>My SAT Exam History</p>
          </h1>
        </div>
        <hr />
        <!-- <el-radio-group :value="isFinished" @input="changeTest" size="medium">
          <el-radio-button :label="null">All</el-radio-button>
          <el-radio-button :label="1">
            Completed
          </el-radio-button>
          <el-radio-button :label="0">
            Incomplete
          </el-radio-button>
        </el-radio-group> -->
        <template v-if="results">
          <div class="text-center" style="margin:15px 0">
            <el-pagination
              background
              @current-change="setPage"
              :page-size="pageSize"
              :current-page="page"
              :total="resultsTotal"
              :page-count="6"
              layout="prev, pager, next"
            >
            </el-pagination>
          </div>
          <TestResultsTable
            :type="type"
            :results="results"
            role="user"
          ></TestResultsTable>
          <div class="text-center" style="margin:15px 0">
            <el-pagination
              background
              @current-change="setPage"
              :page-size="pageSize"
              :current-page="page"
              :total="resultsTotal"
              :page-count="6"
              layout="prev, pager, next"
            >
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import TestResultsTable from "@/views/sat/components/TestResultsTable";
import ACTList from "@/views/act/apis/list.js";

export default {
  metaInfo() {
    return {
      title: "ACT Exam History - XMocks"
    };
  },

  components: { TestResultsTable },

  mixins: [],

  props: [],
  data() {
    return {
      search: {
        user_keyword: null,
        test_title: null
      },
      active: null,
      results: null,
      resultsTotal: 0,
      pageSize: 0,
      user_exams: {
        current_page: 1,
        data: [
          {
            id: 22414,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 1012,
            is_finished: 0,
            total_score: 420,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 2,
                questionOrder: 27
              },
              leaveInfo: {
                leaveTimes: 0,
                leaveSeconds: 8
              },
              first: "E"
            },
            created_at: "2024-06-18T07:43:28.000000Z",
            updated_at: "2024-08-12T08:51:03.000000Z",
            correct_count: "2",
            question_count: 0,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 1012,
              user_id: "7ea99d82-92d2-433e-bcc6-68c91ea9e86f",
              company_id: 19,
              title: "202403A Digital SAT",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 147,
              question_count: 98,
              exam_questions_count: 0
            }
          },
          {
            id: 34831,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 236,
            is_finished: 1,
            total_score: 480,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 4,
                questionOrder: 22
              },
              leaveInfo: {
                leaveTimes: 1,
                leaveSeconds: 2
              },
              first: "E"
            },
            created_at: "2024-08-07T17:09:39.000000Z",
            updated_at: "2024-08-07T17:13:28.000000Z",
            correct_count: "8",
            question_count: 98,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 236,
              user_id: null,
              company_id: null,
              title: "SATMocks Full Practice Test 10 (Linear)",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 37,
              question_count: 98,
              exam_questions_count: 98
            }
          },
          {
            id: 34660,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 178,
            is_finished: 1,
            total_score: 400,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 4,
                questionOrder: 22
              },
              leaveInfo: {
                leaveTimes: 4,
                leaveSeconds: 99
              },
              first: "E"
            },
            created_at: "2024-08-07T06:19:57.000000Z",
            updated_at: "2024-08-07T06:30:57.000000Z",
            correct_count: "3",
            question_count: 98,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 178,
              user_id: null,
              company_id: null,
              title: "SATMocks Full Practice Test 3 (Linear)",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 24,
              question_count: 98,
              exam_questions_count: 98
            }
          },
          {
            id: 34659,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 178,
            is_finished: 0,
            total_score: 0,
            other_status: {
              from: "super-admin",
              timeType: 1
            },
            created_at: "2024-08-07T06:19:51.000000Z",
            updated_at: "2024-08-07T06:19:51.000000Z",
            correct_count: 0,
            question_count: 98,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 178,
              user_id: null,
              company_id: null,
              title: "SATMocks Full Practice Test 3 (Linear)",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 24,
              question_count: 98,
              exam_questions_count: 98
            }
          },
          {
            id: 34581,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 178,
            is_finished: 0,
            total_score: 0,
            other_status: {
              from: "super-admin",
              timeType: 1
            },
            created_at: "2024-08-07T03:14:11.000000Z",
            updated_at: "2024-08-07T03:14:11.000000Z",
            correct_count: 0,
            question_count: 98,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 178,
              user_id: null,
              company_id: null,
              title: "SATMocks Full Practice Test 3 (Linear)",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 24,
              question_count: 98,
              exam_questions_count: 98
            }
          },
          {
            id: 32619,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 793,
            is_finished: 1,
            total_score: 420,
            other_status: {
              from: "super-admin",
              adaptive_practice_id: 3,
              adaptive_practice_exam_id: 1021,
              timeType: 1,
              pastOrder: {
                sectionOrder: 4,
                questionOrder: 22
              },
              leaveInfo: {
                leaveTimes: 0,
                leaveSeconds: 0
              },
              first: "E"
            },
            created_at: "2024-07-31T03:40:15.000000Z",
            updated_at: "2024-07-31T03:41:31.000000Z",
            correct_count: "2",
            question_count: 0,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 793,
              user_id: null,
              company_id: null,
              title: "Barron's Digital SAT Test 4 (English Easy, Math Easy)",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 96,
              question_count: 98,
              exam_questions_count: 0
            }
          },
          {
            id: 28213,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 1013,
            is_finished: 1,
            total_score: 690,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 4,
                questionOrder: 22
              },
              leaveInfo: {
                leaveTimes: 0,
                leaveSeconds: 0
              },
              first: "E"
            },
            created_at: "2024-07-15T11:41:56.000000Z",
            updated_at: "2024-07-30T07:53:31.000000Z",
            correct_count: "23",
            question_count: 0,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 1013,
              user_id: "7ea99d82-92d2-433e-bcc6-68c91ea9e86f",
              company_id: 19,
              title: "202403B Digital SAT",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 148,
              question_count: 98,
              exam_questions_count: 0
            }
          },
          {
            id: 22427,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 1013,
            is_finished: 1,
            total_score: 460,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 4,
                questionOrder: 22
              },
              leaveInfo: {
                leaveTimes: 0,
                leaveSeconds: 0
              },
              first: "E"
            },
            created_at: "2024-06-18T11:41:21.000000Z",
            updated_at: "2024-07-30T07:53:22.000000Z",
            correct_count: "5",
            question_count: 0,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 1013,
              user_id: "7ea99d82-92d2-433e-bcc6-68c91ea9e86f",
              company_id: 19,
              title: "202403B Digital SAT",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 148,
              question_count: 98,
              exam_questions_count: 0
            }
          },
          {
            id: 22428,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 1013,
            is_finished: 0,
            total_score: 400,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 2,
                questionOrder: 27
              },
              leaveInfo: {
                leaveTimes: 0,
                leaveSeconds: 0
              },
              first: "E"
            },
            created_at: "2024-06-18T11:42:35.000000Z",
            updated_at: "2024-07-30T07:53:22.000000Z",
            correct_count: 0,
            question_count: 0,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 1013,
              user_id: "7ea99d82-92d2-433e-bcc6-68c91ea9e86f",
              company_id: 19,
              title: "202403B Digital SAT",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 148,
              question_count: 98,
              exam_questions_count: 0
            }
          },
          {
            id: 22486,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 1013,
            is_finished: 0,
            total_score: 400,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 2,
                questionOrder: 27
              },
              leaveInfo: {
                leaveTimes: 0,
                leaveSeconds: 0
              },
              first: "E"
            },
            created_at: "2024-06-19T01:05:47.000000Z",
            updated_at: "2024-07-30T07:53:22.000000Z",
            correct_count: 0,
            question_count: 0,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 1013,
              user_id: "7ea99d82-92d2-433e-bcc6-68c91ea9e86f",
              company_id: 19,
              title: "202403B Digital SAT",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 148,
              question_count: 98,
              exam_questions_count: 0
            }
          },
          {
            id: 31772,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 794,
            is_finished: 0,
            total_score: 400,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 1,
                questionOrder: 27
              },
              leaveInfo: {
                leaveTimes: 3,
                leaveSeconds: 27
              },
              first: "E"
            },
            created_at: "2024-07-29T03:02:25.000000Z",
            updated_at: "2024-07-29T03:03:18.000000Z",
            correct_count: 0,
            question_count: 0,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 794,
              user_id: null,
              company_id: null,
              title: "Barron's Digital SAT Test 1",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 97,
              question_count: 98,
              exam_questions_count: 0
            }
          },
          {
            id: 29481,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 1019,
            is_finished: 1,
            total_score: 590,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 4,
                questionOrder: 22
              },
              leaveInfo: {
                leaveTimes: 0,
                leaveSeconds: 0
              },
              first: "E"
            },
            created_at: "2024-07-19T01:29:09.000000Z",
            updated_at: "2024-07-19T05:57:08.000000Z",
            correct_count: "14",
            question_count: 0,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 1019,
              user_id: "7ea99d82-92d2-433e-bcc6-68c91ea9e86f",
              company_id: 19,
              title: "202405 Asia A Digital SAT",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 149,
              question_count: 97,
              exam_questions_count: 0
            }
          },
          {
            id: 29608,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 208,
            is_finished: 0,
            total_score: 400,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 3,
                questionOrder: 1,
                testTime: 2091
              },
              leaveInfo: {
                leaveTimes: 1,
                leaveSeconds: 4
              },
              first: "E"
            },
            created_at: "2024-07-19T05:33:04.000000Z",
            updated_at: "2024-07-19T05:34:00.000000Z",
            correct_count: 0,
            question_count: 98,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 208,
              user_id: null,
              company_id: null,
              title: "SATMocks Full Practice Test 4 (Linear)",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 30,
              question_count: 98,
              exam_questions_count: 98
            }
          },
          {
            id: 29251,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 1055,
            is_finished: 0,
            total_score: 420,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 4,
                questionOrder: 1,
                testTime: 2097
              },
              leaveInfo: {
                leaveTimes: 1,
                leaveSeconds: 177
              },
              first: "E"
            },
            created_at: "2024-07-18T03:29:05.000000Z",
            updated_at: "2024-07-18T03:36:28.000000Z",
            correct_count: "1",
            question_count: 0,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 1055,
              user_id: "7ea99d82-92d2-433e-bcc6-68c91ea9e86f",
              company_id: 19,
              title: "202406B Digital SAT",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 154,
              question_count: 98,
              exam_questions_count: 0
            }
          },
          {
            id: 29168,
            user_id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
            exam_id: 1055,
            is_finished: 1,
            total_score: 610,
            other_status: {
              from: "super-admin",
              timeType: 1,
              pastOrder: {
                sectionOrder: 4,
                questionOrder: 22
              },
              leaveInfo: {
                leaveTimes: 1,
                leaveSeconds: 2
              },
              first: "E"
            },
            created_at: "2024-07-18T01:45:30.000000Z",
            updated_at: "2024-07-18T01:50:26.000000Z",
            correct_count: "14",
            question_count: 0,
            user: {
              id: "beaa04f2-6c69-4ccd-9024-9de4f9f8582d",
              first_name: "baicen",
              last_name: "Lu",
              ivy_way_login_information: null
            },
            exam: {
              id: 1055,
              user_id: "7ea99d82-92d2-433e-bcc6-68c91ea9e86f",
              company_id: 19,
              title: "202406B Digital SAT",
              test_type: "Modules\\Practice\\Entities\\ComposePractice",
              test_id: 154,
              question_count: 98,
              exam_questions_count: 0
            }
          }
        ],
        first_page_url:
          "https://api.satmocks.com/api/user-exam/compose-practice?page=1",
        from: 1,
        last_page: 2,
        last_page_url:
          "https://api.satmocks.com/api/user-exam/compose-practice?page=2",
        links: [
          {
            url: null,
            label: "&laquo; Previous",
            active: false
          },
          {
            url:
              "https://api.satmocks.com/api/user-exam/compose-practice?page=1",
            label: "1",
            active: true
          },
          {
            url:
              "https://api.satmocks.com/api/user-exam/compose-practice?page=2",
            label: "2",
            active: false
          },
          {
            url:
              "https://api.satmocks.com/api/user-exam/compose-practice?page=2",
            label: "Next &raquo;",
            active: false
          }
        ],
        next_page_url:
          "https://api.satmocks.com/api/user-exam/compose-practice?page=2",
        path: "https://api.satmocks.com/api/user-exam/compose-practice",
        per_page: 15,
        prev_page_url: null,
        to: 15,
        total: 24
      }
    };
  },
  computed: {
    ...mapState("user", ["token", "lang", "profile"]),
    isFinished() {
      let isFinished = null;
      if (parseInt(this.$route.query.is_finished) > -1) {
        isFinished = parseInt(this.$route.query.is_finished);
      }
      return isFinished;
    },
    page() {
      let page = 1;
      if (this.$route.query.page) {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    type() {
      if (this.$route.query.type) {
        return this.$route.query.type;
      } else {
        return "singlePractice";
      }
    }
  },
  watch: {
    profile() {
      this.getResults();
    }
  },

  mounted() {
    this.getResults();
  },

  methods: {
    async getResults() {
      if (this.profile && this.profile.id) {
        const res = await ACTList.getResults(this.profile.id, {
          test_name: "SAT",
          page: this.page
        });
        this.results = res.user_exams.data;
        this.pageSize = res.user_exams.per_page;
        this.resultsTotal = res.user_exams.total;
      }
    },
    changeTest(val) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: 1, is_finished: val }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
.act-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
}

.td-section a {
  margin-right: 15px;
}
.table th {
  color: white !important;
}
.sat {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.box .container {
  padding-top: 0;
}

.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 0px;
}
</style>
<style scoped src="@/views/act/assets/css/act.css"></style>
<style scoped src="@/views/act/assets/css/simplebar.css"></style>
<style scoped src="@/views/act/assets/css/app.min.css"></style>
